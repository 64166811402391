<template>
  <el-form
    :model="registerStep1"
    status-icon
    hide-required-asterisk
    :rules="rules"
    ref="registerStep1"
    id="register-form-step1"
    class="register-form"
    label-width="80px"
  >
    <el-form-item label="會員帳號" prop="account">
      <el-input
        type="email"
        v-model.trim="registerStep1.account"
        placeholder="請輸入信箱"
      ></el-input>
    </el-form-item>
    <el-form-item label="設定密碼" prop="password">
      <el-input
        type="password"
        v-model.trim="registerStep1.password"
        placeholder="至少包含8碼及英文，可混合數字/符號"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="確認密碼" prop="checkpass">
      <el-input
        type="password"
        v-model.trim="registerStep1.checkpass"
        placeholder="請再次輸入密碼"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="申請類型" prop="type">
      <el-select
        v-model="registerStep1.type"
        placeholder="請選擇"
        class="select-width"
      >
        <el-option
          v-for="item in registerStep1.typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-button
      type="primary"
      @click="nextStep('registerStep1')"
      class="sign-btn"
    >
      下一步
    </el-button>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'register-form-step1',
  props: ['nowStep'],
  data() {
    //驗證密碼輸入
    var validatePass = (rule, value, callback) => {
      const regex = /^(?=.*[A-Za-z])[A-Za-z\d]{8,}$/;

      if (!regex.test(value)) {
        callback(new Error('密碼至少包含8碼及英文'));
      } else {
        callback();
      }
    };

    //檢查兩次輸入的密碼
    var validateCheckPass = (rule, value, callback) => {
      if (value !== this.registerStep1.password) {
        callback(new Error('輸入密碼不一致'));
      } else {
        callback();
      }
    };
    return {
      registerStep1: {
        account: this.$store.getters.getRegisterState.account,
        password: '',
        checkpass: '',
        type: this.$store.getters.getRegisterState.user_type
          ? this.$store.getters.getRegisterState.user_type
          : 1,
        typeOptions: [
          { label: '個人', value: 1 },
          { label: '企業', value: 2 },
          { label: '學術單位/公益團體', value: 3 },
        ],
      },
      rules: {
        account: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: '請輸入正確的信箱',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          { validator: validatePass, trigger: 'blur' },
        ],
        checkpass: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          { validator: validateCheckPass, trigger: 'blur' },
        ],
        type: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions(['setRegisterStep1']),
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.registerStep1.type);
          this.$emit('update:nowStep', 2);
          this.setRegisterStep1({
            account: this.registerStep1.account,
            password: this.registerStep1.password,
            user_type: this.registerStep1.type,
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  box-sizing: border-box;
  max-width: 440px;
  background-color: #fff;
  padding: 40px 30px;
  margin: 0 auto;
}
.sign-btn {
  width: 100%;
  margin: 10px 0;
}

//修正select寬度與input一致
.select-width {
  width: 100%;
  max-width: 300px;
}
</style>
