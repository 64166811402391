<template>
  <div class="register">
    <s-header></s-header>
    <div class="register-content">
      <p style="font-size: 22px">註冊會員</p>
      <transition name="fade" mode="out-in">
        <!-- 步驟一 -->
        <register-form-step1
          v-if="nowStep === 1"
          :nowStep.sync="nowStep"
        ></register-form-step1>
        <!-- 步驟二 -->
        <register-form-step2
          v-else-if="nowStep === 2"
          :nowStep.sync="nowStep"
        ></register-form-step2>
      </transition>
    </div>
  </div>
</template>

<script>
import SHeader from '../components/signInSignUp/SHeader';
import registerFormStep1 from '../components/signInSignUp/registerFormStep1';
import registerFormStep2 from '../components/signInSignUp/registerFormStep2';
export default {
  components: {
    SHeader,
    registerFormStep1,
    registerFormStep2,
  },
  name: 'register',
  data() {
    return {
      nowStep: 1,
    };
  },
  created() {
    sessionStorage.clear();
    localStorage.clear();
  },
};
</script>

<style scoped>
.register {
  text-align: center;
  color: rgb(107, 107, 107);
  font-family: PingFangTC-Regular;
}

.register-content {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
