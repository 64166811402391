<template>
  <el-form
    v-loading="loading"
    :model="registerStep2"
    status-icon
    hide-required-asterisk
    :rules="rules"
    ref="registerStep2"
    id="register-form-step2"
    class="register-form"
    :class="{ small: registerStep2.user_type === 1 }"
    label-width="100px"
  >
    <!-- 填寫表格 -->
    <div class="fillin-form">
      <!-- 基本表格：大家都有的-->
      <div class="basic-form">
        <el-form-item label="申請人姓名" prop="name">
          <el-input v-model.trim="registerStep2.name"></el-input>
        </el-form-item>
        <el-form-item label="職業別" prop="occupation">
          <el-select
            v-model="registerStep2.occupation"
            placeholder="請選擇"
            class="select-width"
          >
            <el-option
              v-for="item in occupationOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="聯絡電話" prop="phone">
          <el-input
            v-model.trim="registerStep2.phone"
            placeholder="EX:02-1234-5678#123"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 詳細表格：非個人才有的 -->
      <div
        v-if="registerStep2.user_type === 2 || registerStep2.user_type === 3"
        class="detail-form"
      >
        <el-form-item
          :label="`${organizationLabel}類別`"
          prop="org_classification"
        >
          <el-select
            v-model="registerStep2.org_classification"
            placeholder="請選擇"
            class="select-width"
          >
            <el-option
              v-for="item in organizationOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${organizationLabel}名稱`" prop="org_name">
          <el-input v-model.trim="registerStep2.org_name"></el-input>
        </el-form-item>
        <el-form-item :label="`${organizationLabel}地址`" prop="org_address">
          <el-input v-model.trim="registerStep2.org_address"></el-input>
        </el-form-item>
        <el-form-item :label="`${organizationLabel}電話`" prop="org_phone">
          <el-input
            v-model.trim="registerStep2.org_phone"
            placeholder="EX:02-1234-5678"
          ></el-input>
        </el-form-item>
        <el-form-item label="統一編號" prop="org_tax">
          <el-input v-model.number="registerStep2.org_tax"></el-input>
        </el-form-item>
      </div>
    </div>
    <!-- 確認表格 -->
    <div class="confirm-form">
      <div class="confirm-form-checkbox">
        <el-form-item prop="is_subscribe">
          <el-checkbox v-model="registerStep2.is_subscribe">
            請發給我產品優惠或促銷產品通知
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="agree" :show-message="false">
          <el-checkbox
            v-model="registerStep2.agree"
            :style="agreeItems.isDisagree ? agreeItems.wrongColor : ''"
            class="fix-align"
          >
            我同意使用Target-Q使用規範
          </el-checkbox>
        </el-form-item>
      </div>
      <div class="confirm-form-button">
        <el-link :underline="false" @click="backto()" class="back-btn">
          上一步
        </el-link>
        <el-button
          type="primary"
          @click="onSubmit('registerStep2')"
          class="sign-btn"
        >
          送出申請
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
//職業別資料
let occupationData = {
  type1: [
    '農林漁牧業',
    '交通運輸業',
    '餐旅業',
    '建築工程業',
    '製造業',
    '新聞廣告業',
    '娛樂業',
    '公共事業',
    '一般商業',
    '服務業',
    '科技資訊業',
    '其他',
  ],
  type2: ['經營者', '主管', '行銷人員', '一般職員', '其他'],
  type3: ['教職員', '學生', '其他'],
};

//企業與單位-類型別資料
let organizationData = {
  type2: [
    '農、林、漁、牧業',
    '礦業及土石採取業',
    '製造業',
    '水電燃氣業',
    '營造及工程業',
    '批發、零售及餐飲業',
    '運輸、倉儲及通信業',
    '金融、保險及不動產業',
    '專業、科學及技術服務類',
    '文化、運動、休閒及其他服務類',
    '其他',
  ],
  type3: ['學術單位', '公益團體'],
};
import { userRegister, resendEmailVerify } from '@/api/userAction';
import emailVerifyBox from '@/mixin/emailVerifyBox';
export default {
  name: 'register-form-step2',
  mixins: [emailVerifyBox],
  props: ['nowStep'],
  data() {
    //驗證同意條款checkbox
    var validateAgree = (rule, value, callback) => {
      if (!value) {
        callback(new Error(''));
        this.agreeItems.isDisagree = true;
      } else {
        callback();
      }
    };
    //驗證電話
    var validateNumber = (rule, value, callback) => {
      const regex = /^[0-9-#()+\s]{7,}$/;

      if (!regex.test(value)) {
        callback(new Error('請輸入正確的電話格式'));
      } else {
        callback();
      }
    };
    //驗證統一編號
    var validateGUI = (rule, value, callback) => {
      const regex = /^\d{8}$/;

      if (!regex.test(value)) {
        callback(new Error('請輸入正確的編號格式'));
      } else {
        callback();
      }
    };
    return {
      registerStep2: {
        //會員資料
        account: this.$store.getters.getRegisterState.account,
        password: this.$store.getters.getRegisterState.password,
        user_type: this.$store.getters.getRegisterState.user_type,
        //基本表格
        name: '',
        occupation: '',
        phone: '',
        //詳細表格
        org_classification: '',
        org_name: '',
        org_address: '',
        org_phone: '',
        org_tax: '',
        //確認表格
        is_subscribe: false,
        agree: false,
      },
      agreeItems: {
        isDisagree: false,
        wrongColor: {
          color: '#F56C6C',
        },
      },
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
        ],
        occupation: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          { validator: validateNumber, trigger: 'blur' },
        ],
        org_classification: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'change',
          },
        ],
        org_name: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
        ],
        org_address: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
        ],
        org_phone: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          { validator: validateNumber, trigger: 'blur' },
        ],
        org_tax: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          { validator: validateGUI, trigger: 'blur' },
        ],
        agree: [{ validator: validateAgree, trigger: 'blur' }],
      },
    };
  },
  computed: {
    //切換不同職業別的資料
    occupationOptions: function () {
      let index = this.registerStep2.user_type;
      return occupationData[`type${index}`];
    },
    //切換企業與單位類型的資料
    organizationOptions: function () {
      let index = this.registerStep2.user_type;
      return organizationData[`type${index}`];
    },
    //切換企業與單位title
    organizationLabel: function () {
      let index = this.registerStep2.user_type;
      return index === 2 ? '企業' : '單位';
    },
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.registerStep2);
          this.loading = true;
          userRegister(this.registerStep2)
            .then((res) => {
              //註冊成功
              console.log(res);
              this.loading = false;
              //驗證信箱彈窗
              this.$_sendEmailVerify(this.registerStep2.account);
            })
            .catch((error) => {
              // 未註冊成功
              this.$alert(error.message, 'ERROR!', {
                type: 'error',
                confirmButtonText: '確定',
                callback: () => {
                  this.$emit('update:nowStep', 1);
                },
              });
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //回上一步
    backto() {
      this.$emit('update:nowStep', 1);
      this.$refs['registerStep2'].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  box-sizing: border-box;
  max-width: 800px;
  background-color: #fff;
  padding: 40px 55px 40px 30px;
  margin: 0 auto;
}
.register-form.small {
  max-width: 400px;
  padding: 40px 30px;
}

.fillin-form {
  display: flex;
  flex-wrap: wrap;
}
.basic-form,
.detail-form,
.confirm-form {
  width: 100%;
  max-width: 340px;
  margin-left: auto;
}

@media (max-width: 764px) {
  .basic-form,
  .detail-form,
  .confirm-form {
    margin: 0 auto;
  }
}

//修正select寬度與input一致
.select-width {
  width: 100%;
  max-width: 240px;
}

.confirm-form-checkbox {
  margin-top: 15px;
}

/deep/ .el-checkbox {
  color: rgb(172, 172, 172);
}

/deep/ .confirm-form-checkbox .el-form-item {
  margin-bottom: 0;
}
/deep/ .confirm-form-checkbox .el-form-item__content {
  line-height: 25px;
}

//修正兩個checkbox對不齊的問題
.confirm-form-checkbox .fix-align {
  margin-left: -20px;
}

.confirm-form-button {
  display: flex;
  justify-content: flex-end;
}

//與上方的label-input對齊
.sign-btn {
  width: 100%;
  max-width: 240px;
  margin: 15px 0 20px;
}
.back-btn {
  box-sizing: border-box;
  width: 100px;
  padding-right: 12px;
  justify-content: flex-end;
  flex-shrink: 0;
}
</style>

<style>
/* 改彈窗樣式 */
.re-verify {
  width: 80%;
  padding: 10px 0;
  font-size: 14px;
}
.verify-messageBox .el-message-box__content {
  padding: 10px 55px;
  text-align: left;
}
</style>
