import { resendEmailVerify } from '@/api/userAction';
const text = {
  login:
    '您尚未完成驗證，請至信箱收信完成驗證，如未收到信件可點擊重新驗證，系統將再次發送信件。',
  register:
    '您的申請已成功送出，系統已發送驗證連結至您的信箱，請至信箱收信完成驗證，如未收到信件可點擊重新驗證，系統將再次發送信件。',
};

const emailVerifyBox = {
  methods: {
    $_sendEmailVerify(account) {
      //   console.log(this.$route.name);
      //   console.log(account);
      this.$alert(text[this.$route.name], '驗證信箱', {
        center: true,
        confirmButtonText: '重新驗證',
        confirmButtonClass: 're-verify',
        customClass: 'verify-messageBox',

        beforeClose: (action, instance, done) => {
          //再發一次驗證碼BTN
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '執行中...';
            resendEmailVerify(account)
              .then((res) => {
                console.log(res);
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = '重新驗證';
                this.$message({
                  type: 'info',
                  message: '已重發新的驗證碼至信箱，請儘速收信完成驗證',
                });
              })
              .catch((error) => {
                this.$alert(error.message, 'ERROR!', {
                  type: 'error',
                  confirmButtonText: '確定',
                });
              });
          } else {
            done();
            this.$router.push('/login');
          }
        },
      }).catch((e) => e);
    },
  },
};

export default emailVerifyBox;
